<template>
<div class="PartObject" :style="listheight">
  <div class="title">参与项目</div>
  <div class="data" v-for="(item,index) in PartData" :key="index">
    <div class="img">
      <img :src="item.LogoImage" alt="" />
    </div>
    <div class="title">{{item.Title}}</div>
    <div class="titleBrief">{{item.Slogan}}</div>
    <div class="coin">
      <div class="icon"></div>
      <div class="number">{{item.ToNumber}}</div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  props: {
    PartData: {
      type: Array
    }
  },
  data(){
    return{
      listheight:''
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      let listheight = document.documentElement.clientHeight - 400
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  }
}
</script>

<style scoped lang="scss">
.PartObject{
  margin: 0 auto;
  margin-top: 20px;
  width: 90%;
  overflow-y: auto;

  .title{
    color: #fff;
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 20px;
  }
  .data{
    width: 100%;
    height: 80px;
    border-radius: 15px;
    box-shadow: 0 1px 2px 1px rgba(255, 255,255, 0.5);
    background: linear-gradient(180deg, #21242e 70%, rgba(255, 255,255, 0.2) 100%);
    color: #fff;
    position: relative;
    margin-bottom: 20px;
    .img{
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #fff;
      border-radius: 10px;
      left: 20px;
      top: 15px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .title{
      position: absolute;
      left: 90px;
      top: 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .titleBrief{
      position: absolute;
      left: 90px;
      top: 40px;
      font-size: 13px;
      font-weight: normal;
    }
    .coin{
      position: absolute;
      right: 10px;
      top: 45px;
      .icon{
        width: 15px;
        height: 15px;
        background-image: url("../../../../../assets/image/Token.png");
        background-size: 100% 100%;
        display: inline-block;
        float: left;
        margin-right: 5px;
      }
      .number{
        font-size: 12px;
        float: left;
        color: #5d5f65;
      }
    }
  }
}

</style>