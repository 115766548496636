<template>
  <div class="NumericalValue">
    <div class="nav-block"></div>
    <div class="block">
      <div class="data">
        <div class="number">{{ConData.TolIntegral}}</div>
        <div class="text">累计获得贡献值</div>
      </div>
      <div class="data">
        <div class="number">{{ConData.NotIntegral}}</div>
        <div class="text">可用贡献值</div>
      </div>
    </div>
    <div class="block">
      <div class="data">
        <div class="number">{{ConData.BanIntegral}}</div>
        <div class="text">冻结贡献值</div>
      </div>
      <div class="data">
        <div class="number">{{ConData.UseIntegral}}</div>
        <div class="text">已用贡献值</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ConData: {
      type: Object
    }
  },
  data() {
    return {

    }
  },

}
</script>

<style scoped lang="scss">
  .NumericalValue{
    width: 90%;
    height: 190px;
    margin: 0 auto;
    border-bottom: 1px solid #444249;
    .nav-block{
      width: 100%;
      height: 35px;
    }
    .block{
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      margin-bottom: 30px;
      .data{
        text-align: center;
        width: 100px;
        font-size: 14px;
        .number{
          font-weight: bold;
          margin-bottom: 5px;
          color: #7c64e0;
        }
        .text{
          color: #f7f6fa;
        }
      }

    }
  }

</style>