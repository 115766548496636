import request from '../utils/request';

//获取贡献值数据
export const ConData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Home/ConData',
        method: 'get',
        params: query
    });
};

//获取贡献值数据
export const PartObjectData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Home/PartObject',
        method: 'get',
        params: query
    });
};

export const getProjectAvailValById = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Home/ProjectAvailValById',
        method: 'get',
        params: query
    });
};

