<template>
  <div class="Home">
    <UserInfo :UserInfo="UserInfo"></UserInfo>
    <NumericalValue :ConData="ConData"></NumericalValue>
    <PartObject :PartData="PartData"></PartObject>
  </div>
</template>

<script>
import UserInfo from './Com/UserInfo.vue'
import NumericalValue from './Com/NumericalValue.vue'
import PartObject from './Com/PartObject.vue'
import {ConData, PartObjectData} from "@/api/Home";

export default {
  data() {
    return {
      UserInfo: {},
      ConData: {
        //总贡献值
        TolIntegral: 0,
        //已使用贡献值
        UseIntegral: 0,
        //未使用贡献值
        NotIntegral: 0,
        //已冻结贡献值
        BanIntegral: 0,
      },
      PartData: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let info = this.$store.state.user.info
      this.UserInfo = info

      let id = info.id
      //获取贡献值数据
      ConData({id}).then(res => {
        let ConData = res.payload
        this.ConData = ConData
      })

      //获取项目基本信息
      PartObjectData({id}).then(res => {
        let data = res.payload
        this.PartData = data
      })

    }
  },
  components: {
    UserInfo,
    NumericalValue,
    PartObject
  },
}


</script>

<style scoped lang="scss">
.Home {
  width: 100%;
  background-color: #15171d;

}
</style>
