<template>
  <div class="UserInfo">
    <div class="avatar">
      <img :src="UserInfo.Avatar" v-if="UserInfo.Avatar" alt=""/>
    </div>
    <div class="name">{{ UserInfo.Name }}</div>
    <div class="phone">{{ UserInfo.Phone }}</div>
    <div class="ToDetails">
      <router-link to="/Detail">贡献值明细</router-link>
    </div>
    <div class="exit" @click="exit">退出账户</div>
  </div>
</template>

<script>

import {Dialog, Notify} from "vant";

export default {
  props: {
    UserInfo: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    exit() {
      Dialog.confirm({
        title: '系统',
        message: '是否确认退出该账户吗？'
      }).then(() => {
        localStorage.removeItem("info");
        this.$store.state.user.info = undefined
        Notify({type: "success", message: '退出成功'});
        this.$router.push('/Login')
      }).catch(() => {
        // on cancel
      });
    }
  }

}
</script>

<style scoped lang="scss">
.UserInfo {
  width: 90%;
  height: 120px;
  position: relative;
  margin: 0 auto;
  border-bottom: 1px solid #444249;

  .avatar {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: #d8d8d8;
    border-radius: 50%;
    left: 0;
    top: 30px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .name {
    position: absolute;
    left: 80px;
    top: 40px;
    color: #feffff;
    font-size: 13px;
  }

  .phone {
    position: absolute;
    left: 80px;
    top: 66px;
    color: #a8a9af;
    font-size: 13px;
  }

  .ToDetails {
    position: absolute;
    right: 0;
    top: 35px;
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 10px;
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    color: #ebedfb;

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  .exit {
    color: red;
    position: absolute;
    right: 20px;
    top: 80px;
    font-size: 13px;
    font-weight: bold;
  }
}
</style>